import React, { FC } from 'react'

import { useModalUser } from 'use/modal/useModal'
import { AvatarImage } from 'components/img/AvatarImage'
import { ButtonClearStyled } from 'components/button/styled'
import { AvatarBadgeStyled } from 'components/img/styled'
import icons from 'components/theme/icons'
import { Icon } from 'components/styled'
import UserInfoExModel, { IUserInfoExModel } from 'model/UserInfoEx.model'
import { TRefetch } from 'use/fetch/interface'

interface IProps {
  user: IUserInfoExModel;
  hideBadge?: boolean;
  button?: any;
  refetch?: TRefetch;
}

export const AvatarCompose: FC<IProps> = ({
  user,
  hideBadge = false,
  button: Button = ButtonClearStyled,
  refetch
}) => {
  const { open } = useModalUser()
  const { avatar, username, likes, numChangesNok, mine } = user
  const image = UserInfoExModel.getAvatar(avatar)
  // const dislikes =  ? ` - ${numChangesNok}` : ''
  // const num = `${likes}${dislikes}`
  const num = likes - numChangesNok
  const className = likes - numChangesNok < 0
    ? 'unlike'
    : (mine ? 'mine': '')
  const onClick = e => {numChangesNok
    e.stopPropagation();
    open(user, refetch && { refetch })
  }

  if (hideBadge) {
    return <Button><AvatarImage src={image} alt={username} /></Button>
  } else {
    return (
      <Button onClick={onClick}>
        <AvatarBadgeStyled
          badgeContent={likes
            ? <span className={className}>{num}</span>
            : <Icon icon={icons.info} />}>
          <AvatarImage src={image} alt={username} />
        </AvatarBadgeStyled>
      </Button>
    )
  }
}


