import { ICollectionModel } from "model/Collection.model";
import { IUserCollectionModel } from "model/UserCollection.model";

import { ISearchCollectionModel } from "./SearchCollection.model";
import { IUserCollectionExModel } from "./UserCollectionEx.model";
import { IUserExchangeModel } from "./UserExchange.model";

export const mapperHomeTopCollections = (item: ISearchCollectionModel): ISearchCollectionModel => item
export const mapperHomeLastCollections = (item: ICollectionModel): ISearchCollectionModel => ({
  collection: item,
  collectors: null
})
export const mapperHomeChanges = (item: IUserExchangeModel): IUserExchangeModel  => item 
export const mapperDashboardActiveChanges = (item: IUserExchangeModel): IUserExchangeModel => item
export const mapperDashboardCollections = (item: IUserCollectionExModel): IUserCollectionExModel => item