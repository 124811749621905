import { ImageStyled } from 'components/img/styled';
import React, { FC, memo } from 'react';
import { IChildrenOpt } from 'utils/interface';
import { ActionWrapper } from '../styled';
import CollectionName from './CollectionName';

import {
  AvatarCollectionStyled,
  CardFooterStyled,
  CardCategoryWhite,
  InfoWrapperCollectionStyled,
} from './styled';

export interface ICardCollection extends IChildrenOpt {
  image: string;
  name: string;
  fav?: boolean;
  sleep?: boolean;
  select: (key: number) => void;
}


const CardCollectionMemo: FC<ICardCollection> = ({
  image,
  name,
  fav,
  sleep,
  children = ''
}) => {

  return (
    <ActionWrapper>
      <AvatarCollectionStyled src={image} alt={name} />
      <CardFooterStyled>
        <CardCategoryWhite>
          <InfoWrapperCollectionStyled>
            <CollectionName name={name} fav={fav} sleep={sleep} />
          </InfoWrapperCollectionStyled>
          {children}
        </CardCategoryWhite>
      </CardFooterStyled>
    </ActionWrapper>
  );
};

export const CardCollection = memo(CardCollectionMemo);
