
import productionEnv from 'setup/environments/production'

export interface IUserInfoExModel {
  iduser: number; // iduser
  username: string; // users.username
  avatar: string; // UserInfo.Avatar
  numCollections: number;
  numChangesStarted: number;
  numChangesFinished: number;
  numChangesWith: number;
  numChangesNok: number;
  lastChange: number;
  lastChangeFinished: number;
  lastLogin: number; // users.last_login
  likes: number;
  mine: number;
  unanswered: number; // SinRepuesta
}

export default class UserInfoExModel {
/*
  iduser: Int!
  username: String
  avatar: String
  numCollections: Int
  numChangesStarted: Int
  numChangesFinished: Int
  numChangesWith: Int
  lastChange: Int
  lastChangeFinished: Int
  lastLogin: Int
  likes: Int
  mine: Int
  unanswered: Int
  */
  static getAvatar = (avatar: string) => avatar && `${productionEnv.path.uploads}${avatar}`
}
