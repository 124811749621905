import DateModel from "model/Date.model"

export enum ChangeState {
  PROGRESS = 'PROGRESS',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED'
}

export const StatesInProgress = [1,2,3,4,5,6]
export const StatesCompleted = [7,8,9]
export const StatesCancelled = [10,11,12,13]

export const ChangeStateIds = {
  NEW: 0,
  INIT: 1,
  READ: 2,
  ACCEPTED: 3,
  RECEIVED1: 5,
  RECEIVED2: 6,
  COMPLETE: 7,
  NOT_RECEIVED1: 8,
  NOT_RECEIVED2: 9,
  CANCELLED: 10,
  REJECTED: 11,
  CANCELLED2: 12,
  CANCELLED3: 13
}

export enum ChangeStateIndex {
  NEW = 'NEW',
  INIT = 'INIT',
  ACCEPTED = 'ACCEPTED',
  RECEIVED = 'RECEIVED',
  NOT_RECEIVED = 'NOT_RECEIVED',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
}
export enum ChangeOffer {
  FILLED = 'FILLED',
  EMPTY = 'EMPTY'
}
export enum ChangeAction {
  ACCEPT = 'Acep',
  CANCEL = 'Canc',
  CHAT = 'Chat',
  HIDE = 'Ocul',
  MODIFY = 'Modi',
  NEW = 'NEW',
  NOT_RECEIVED = 'NORe',
  RECEIVED = 'Reci',
  RECEIVED_WRONG = 'RecM',
  REJECT = 'Rech',
}

export enum ChangeFilter {
  SORT = 'sort',
  SEARCH = 'search',
  STATE = 'state',
  COLLECTOR = 'user',
  COLLECTION = 'collection'
}

export enum NewChangeFilter {
  SORT = 'sort',
  SEARCH = 'search',
  OFFER = 'offer',
  COLLECTION = 'collection',
  COLLECTOR = 'user',
  FAV_COLLECTOR = 'favCollector',
  NUM_ITEMS = 'numItems'
}
export interface IChangeChat {
  date?: string;
  text: string;
  name?: string;
}

export interface IUserChangeModel {
  id: string; // IdCamb
  chat: string; // Chat
  stateId: number; // Estado
  changeState?: string; // ChangeState
  startDate: Date; // FechaInicio
  endDate: Date; // Fechafin
  lastUpdater: number; // ModR
  him: boolean; // N or R
}

export default class UserChangeModel {
  
  static getState(stateId: number) {
    return {
      0: ChangeStateIndex.NEW,
      1: ChangeStateIndex.INIT,
      3: ChangeStateIndex.ACCEPTED,
      5: ChangeStateIndex.RECEIVED,
      6: ChangeStateIndex.RECEIVED,
      7: ChangeStateIndex.FINISHED,
      8: ChangeStateIndex.NOT_RECEIVED,
      9: ChangeStateIndex.NOT_RECEIVED,
      10: ChangeStateIndex.CANCELLED,
      11: ChangeStateIndex.CANCELLED,
      12: ChangeStateIndex.CANCELLED,
      13: ChangeStateIndex.CANCELLED,
      14: ChangeStateIndex.CANCELLED
    }[stateId]
  }

  static isSelf = (change: IUserChangeModel, otherUserId: number) => {
    const { stateId, him, lastUpdater } = change
    let self = [6, 9].indexOf(stateId) === -1 ? !him : him;
    // switch self depending on last modifier
    if (stateId === 1) {
      self = lastUpdater !== otherUserId;
    }
    return self
  }
  static parseChatItem = (item: string):IChangeChat => {
    if (!item) {
      return {
        date: '',
        text: ''
      }
    }
    const isMessage = item.indexOf('>') !== -1
    if (isMessage) {
      const [ info, text ] = item.split('>')
      const lastIndex = info.lastIndexOf('-')
      const date = info.slice(0, lastIndex)
      const name = info.slice(lastIndex + 1, info.length)
      
      return {
        date: DateModel.dateDiff(date), text, name
      }
    } else {
      const [ text, date ] = item.split('(')
      return {
        date: DateModel.dateDiff(date.split(')')[0]), text
      }
    }
  }
  static parseChat = (chat: string):IChangeChat => {
    if (!chat) {
      return {
        date: '',
        text: ''
      }
    }
    
    const twoLast = chat.split('<br>').slice(-2)
    let last = twoLast.slice(-1)[0]
    const isComenta = last.indexOf('Comenta:') !== -1
    if (isComenta) {
      const [ name, text ] = last.split(' Comenta: ')
      return {
        name,
        text
      }
    } else {
      return this.parseChatItem(last)
    }
  }
/*
  {
    Chat: ""
    CodigoC: 8
    Comentarios: "comentario"
    Estado: "10"
    FechaF: 0
    FechaI: 1515804418
    FechaInicio: "2018-01-12 19:46:58"
    Fechafin: "2018-01-22 19:14:28"
    IdCamb: 16
    IduserN: 73
    IduserR: 22
    ModN: ""
    ModR: 0
    croENP: ""
    repotroENP: ""
  }
*/
}
