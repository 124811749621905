import moment from 'moment'


export enum IDateFormat {
  short = 'D MMM YYYY',
  long = 'D [de] MMMM [de] YYYY',
  db = 'YYYY-MM-DD'
}
export default class DateModel {
  static format = (dateStr: string| number, format = IDateFormat.short) => moment(dateStr).format(format)

  static dateDiff = (dateStr: string | number, showHours = false) => {
    const now = moment()
    const months = now.diff(dateStr, 'months')
    if (isNaN(months)) {
      return ''
    }
    if (months < 2) {
      const days = now.diff(dateStr, 'days')
      if (days < 2) {
        if (showHours) {
          return now.diff(dateStr, 'hours') + ' horas'
        } else {
          if (days < 1) {
            return 'hoy'
          } else {
            return 'ayer'
          }
        }
      }
      return days + ' días'
    } else {
      if (months < 12) {
        return months + ' meses'
      }
      const years =  Math.floor(months / 12)
      return `${years} año${years > 1 ? 's' : ''}`
    }
  }

  static dateDiffUnit = (dateStr: string | number, unit: moment.unitOfTime.Diff) => {
    const now = moment()
    const diff = now.diff(dateStr, unit)
    return diff
  }
}