import React, { FC } from 'react'
import { ExchangeBadge, ExchangeIcon } from './styled'

interface IExchangeProps {
  className?: string;
  labelMine?: string;
  labelHis?: string;
  mine: number;
  his: number;
}
export const Exchange: FC<IExchangeProps> = ({ mine, labelMine, his, labelHis, className }) => (
  <span className={className}>
    <ExchangeBadge>
      {labelMine}
      <span>{mine}</span>
    </ExchangeBadge>
    <ExchangeIcon />
    <ExchangeBadge>
      {labelHis}
      <span>{his}</span>
    </ExchangeBadge>
  </span>
)