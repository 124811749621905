import { IChangeCollectionListProps } from 'compose/popup/change/ChangeCollection'
import SortUtility from 'use/filter/SortUtility'
import { TElement } from 'utils/interface'
import { ICollectionModel } from './Collection.model'
import { IUserChangeModel } from './UserChange.model'
import { mapArrayBy } from 'utils/helpers'
import { IUserInfoExModel } from './UserInfoEx.model'
export interface IExchangeData {
  collection: ICollectionModel;
  mine: [string];
  his: [string];
}
export interface IUserExchangeModel {
  change: IUserChangeModel;
  user: IUserInfoExModel;
  exchanges: [IExchangeData];
}
export default class UserExchangeModel {

  static serializeCols = (
    cols: IChangeCollectionListProps[]
  ): string => {
    const serial = cols.reduce((arr, col) => {
      if (col.selected.length) {
        arr.push([col.code, col.selected.join(',')].join(':'))
      }
      return arr
    }, []).join(';')
    return serial
  }
  static joinCols = (
    prev: IChangeCollectionListProps[],
    update: IChangeCollectionListProps[]
  ): IChangeCollectionListProps[] => {
    const result = {}
    const getUpdated = (
      prev: IChangeCollectionListProps,
      upd?: IChangeCollectionListProps): IChangeCollectionListProps =>
    ({
      code: prev.code,
      name: prev.name,
      selected: upd && upd.selected ? prev.selected.concat(upd.selected) : prev.selected,
      unselected: upd && upd.unselected ? prev.unselected.concat(upd.unselected) : prev.unselected,
    })

    prev.forEach(cols => {
      result[cols.code] = getUpdated(cols)
    })
    update.forEach(cols => {
      const prevCols = result[cols.code]
      result[cols.code] = getUpdated(cols, prevCols)
    })
    return Object.values(result)
  }
  static getTotal = (exchanges: IExchangeData[]) => {
    return exchanges.reduce((tot, exchange: IExchangeData) => {
      tot.mine += exchange.mine.length
      tot.his += exchange.his.length
      return tot
    },{ mine: 0, his: 0 })
  }

  static exchangesProccess = (
    exchanges: IExchangeData[] = [],
    key: string,
    isNew: boolean
  ): IChangeCollectionListProps[] => {
    return exchanges
      .map(exchange => {
        const items = exchange[key]

        let selected = []
        let unselected = []
        if (isNew) {
          unselected = items
        } else {
          selected = items
        }
        return {
          code: exchange.collection.code,
          name: exchange.collection.name,
          selected,
          unselected
        }})
      .filter(o => o.selected.length || o.unselected.length)
  }

  static parseTotal = (arr: Partial<IChangeCollectionListProps>[]): number => {
    return arr.reduce(
      (tot, acc: IChangeCollectionListProps) => {
        tot += acc.selected.length
        return tot
      }, 0)
  }

  static sort = (list: TElement[]): TElement[] => {
    return list.filter(Boolean)
      .sort(SortUtility.compare.digits).reverse()
  }

  static auto = (
    off: IChangeCollectionListProps[], 
    req: IChangeCollectionListProps[]
  ): IChangeCollectionListProps[][] => {
    const totalOff = getTotalItems(off)
    const totalReq = getTotalItems(req)
    const num = Math.min(totalOff, totalReq)
    const randomOff = processList(off || [], totalOff, num)
    const randomReq = processList(req || [], totalReq, num)
  
    return [ randomOff, randomReq ]
  }

  static sortAll = (list: IChangeCollectionListProps[]) =>
    list.map(ob => ({
      ...ob,
      ...{
        selected: UserExchangeModel.sort(ob.selected),
        unselected: UserExchangeModel.sort(ob.unselected)
      }
    }))

  static getCodes = (
    exchanges: IExchangeData[]
  ) => Object.keys(mapArrayBy(exchanges, 'collection.code'))
    .map(Number)
}

const getNumItems = (col: IChangeCollectionListProps) => col.selected.length + col.unselected.length
const getTotalItems = (list: IChangeCollectionListProps[]) =>
  list.reduce((tot, col) => tot + getNumItems(col), 0)

const randomValues = (list: string | any[], l: any) => {
  var arr = shuffle(list.length)

  return arr.slice(0, l).map(function (i: string | number) {
    return list[i]
  })
}

const processList = (
  list: IChangeCollectionListProps[],
  total: number,
  num: number
): IChangeCollectionListProps[] => {
  const sort = SortUtility.compare.number
  const randData: number[] = randomValues(range(total), num).sort(sort).reverse()
  const selection: IChangeCollectionListProps[] = []

  const next = (x: number) => {
    const flatcol = list[x].selected.concat(list[x].unselected)
    return {
      flatcol,
      len: flatcol.length,
    }
  }

  const push = (  
    selected: TElement[],
    all: TElement[],
    col: IChangeCollectionListProps
  ): IChangeCollectionListProps => {
    const selectedCodes = selected.reduce((tot, item) => {
      tot[item] = true
      return tot
    }, {})
    return {
      code: col.code,
      name: col.name,
      selected,
      unselected: all.filter(item => !selectedCodes[item])
    }
  }
  let n = 0
  let ob = next(n)
  let offset = 0
  let selected: TElement[] = []

  randData.forEach(function (index, i) {
    const isNextCollection = index >= ob.len + offset
    if (isNextCollection) {
      selection.push(push(selected, ob.flatcol, list[n]))

      n++
      offset += ob.flatcol.length
      ob = next(n)
      selected = []
    }
    selected.push(ob.flatcol[index - offset])
  })
  selection.push(push(selected, ob.flatcol, list[n]))
  return selection
}

function range(i: number): number[] {
  return i <= 0 ? [] : range(i-1).concat(i-1)
}

function shuffle(len: number) {
  const array = range(len)
  let top = array.length
  let tmp: any
  let current: number
  if(top) while(--top) {
    current = Math.floor(Math.random() * (top + 1))
    tmp = array[current]
    array[current] = array[top]
    array[top] = tmp
  }
  return array
}
