import { Icon } from 'components/styled';
import React, { FC } from 'react';
import icons from 'components/theme/icons';

import {
  NameStyled,
  IconDataStyled,
  NameVStyled,
} from './styled';


interface ICollectionName {
  name?: string;
  fav?: boolean;
  sleep?: boolean;
  className?: string;
  variant?: 'horizontal' | 'vertical';
}
const CollectionName: FC<ICollectionName> = ({
  name = '',
  fav,
  sleep,
  className,
  variant
}) => {

  const Wrapper = {
    horizontal: NameStyled,
    vertical: NameVStyled
  }[variant || 'horizontal']

  return (
    <Wrapper size={name.length} className={className}>
      { (fav || sleep) &&
        <span>
          <IconDataStyled>
            { fav && <Icon icon={icons.fav} />}
            { sleep && <Icon icon={icons.sleep} />}
          </IconDataStyled>
        </span>
      }
      {name}
    </Wrapper>
  )
}

export default CollectionName