import styled from 'styled-components';

import {
  ChatStyled,
  DateStyled,
} from '../styled'

import { blackColor, infoColor, roseColor } from 'components/theme/constants';
import { Avatar } from 'components/core';
import { Badge, Icon } from 'components/styled';
import icons from 'components/theme/icons';
import { EllipsisStyled, EllipsisParentStyled } from 'components/common/common.styled';
import { ButtonClearStyled } from 'components/button/styled';


export const ButtonAvatarChangeStyled = styled(ButtonClearStyled)`
  .MuiAvatar-root {
    width: 2rem;
    height: 2rem;
  }

  position: absolute;
`
export const ButtonAvatarChangeVStyled = styled(ButtonClearStyled)`
  width: 3rem;
  height: 3rem;
  margin: 0 0.5rem 0 0;
`
export const AvatarCollectionStyled = styled(Avatar)`
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.2rem 0 0;
`
export const ButtonAvatarStyled = styled(ButtonClearStyled)`
  display: flex;
`
export const CardAvatarWrapper = styled.div`
  min-width: 0;
  display: flex;
  margin-bottom: 0.5rem;
`
export const CardFooterStyled = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
  width: 100%;
  color: ${roseColor[0]};
`
export const CardBodyStyled = styled.div`
  overflow: hidden;
  height: 2.3rem;
  margin: 0;
`
export const CardHeaderStyled = styled.div`
  color: #FFF;
  margin: 0;
  padding: .5rem;
  position: relative;
  font-size: 0.7rem;
  background: ${blackColor};
  border-radius: 4px 4px 0 0;
`
export const CardHeaderChangeStyled = styled(CardHeaderStyled)`
`
export const StateStyled = styled.div`
  font-size: 0.6rem;
`
export const DateChangeStyled = styled(DateStyled)`
  font-size: 0.6rem;
  padding-left: 0.4rem;
  display: inline;
`

export const NameCollectionStyled = styled(EllipsisStyled)`
  color: ${blackColor};
  font-size: 0.6rem;
  margin-bottom: -0.2rem;
  width: 100%;
  text-align: left;
  font-weight: normal;
`
export const InfoWrapperStyled = styled(EllipsisParentStyled)`
  display: grid;
  width: 100%;
  text-align: right;
`
export const IconStyled = styled(Icon)`
  position: absolute;
  right: .5rem;
  bottom: .5rem;
`
export const ChatChangeStyled = styled(ChatStyled)`
  padding: 0.2rem;
  font-size: 0.6rem;
  line-height: 0.8rem;
  height: 2.1rem;
  overflow: hidden;
`
export const ExchangeStyled = styled.div`
  padding: 0.1rem 0.2rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1.7rem 1fr;
`
export const ExchangeBadge = styled.span`
  font-size: 0.6rem;
  & span {
    font-size: 0.7rem;
    padding: 0 0 0 0.1rem;
  }
`
export const ExchangeIcon = styled(Icon).attrs(props => ({
  icon: icons.exchange
}))`
  padding: 0 .3rem 0 .2rem;
  font-size: 1.2rem;
  vertical-align: middle;
  color: inherit;
`

export const ExchangeWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  overflow: hidden;
  color: ${infoColor[0]};
`

export const StateBadge = styled(Badge) `
  margin: 0.2rem;
  padding: 0.2rem 0.4rem;
  text-transform: lowercase;
`
