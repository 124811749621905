import React, { FC, memo } from 'react';
import icons from 'components/theme/icons';

import { IChildrenOpt } from 'utils/interface';
import { ActionWrapper, CardBlockStyled } from '../styled';

import {
  CardHeaderChangeStyled,
  CardBodyStyled,
  CardFooterStyled,
  ChatChangeStyled,
  DateChangeStyled,
  IconStyled,
  InfoWrapperStyled,
  StateStyled,
} from './styled';
import { NameWrapper } from 'components/common/NameWrapper';


export interface IChangeCard extends IChildrenOpt {
  name?: string;
  date?: string;
  stateActual?: string;
  chat?: string;
  avatar: React.ReactNode;
  hideIcon?: boolean;
}

const CardChangeMemo: FC<IChangeCard> = ({
  name = '',
  chat = '',
  avatar = '',
  date = '',
  stateActual = '',
  children = '',
  hideIcon = false
}) => {
  return (
    <ActionWrapper>
      <CardBlockStyled>
        <CardHeaderChangeStyled>
          {avatar}
          <InfoWrapperStyled>
            <StateStyled>{stateActual}</StateStyled>
            <DateChangeStyled>{date}</DateChangeStyled>
            <NameWrapper name={name}>{ name }</NameWrapper>
          </InfoWrapperStyled>
          { !hideIcon && <IconStyled icon={icons.unfold}/> }
        </CardHeaderChangeStyled>
        { chat &&
          <CardBodyStyled>
            <ChatChangeStyled>{chat}</ChatChangeStyled>
          </CardBodyStyled>
        }
      </CardBlockStyled>
      <CardFooterStyled>
        {children}
      </CardFooterStyled>
    </ActionWrapper>
  );
};

export const CardChange = memo(CardChangeMemo);
