import styled from 'styled-components'

import {
  ActionWrapper,
  CardBlockStyled,
  FooterOverlayStyled,
  InfoWrapperStyled
} from '../styled'

import { blackColor, whiteColor } from 'components/theme/constants'
import { Icon, Paragraph } from 'components/styled'
import { AvatarImage } from 'components/img/AvatarImage'
import { EllipsisStyled } from 'components/common/common.styled'


export const ActionBlockWrapper = styled (ActionWrapper)`
  width: 100%;
  height: 5rem;
  margin: 0;
  line-height: .8rem;
  padding: 0.3rem;
  display: grid;
`

export const AdditionalDataStyled = styled.div`
  margin: 0.3rem 0;
  min-height: 1.5rem;
`
export const AdditionalGridStyled = styled.div`
  margin: 0 0 0.3rem 0;
  display: grid;
  padding: 0.2rem;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  & p {
    text-align: left;
    margin: 0 0 0 0.5rem;
  }
`
export const AdditionalParagraphStyled = styled(Paragraph)`
  font-weight: bold;
  
  margin: 0 auto;
`
export const AvatarCollectionStyled = styled(AvatarImage).attrs(props => ({
  shadow: true
}))`
  height: 8rem;
  width: auto;
  border-radius: 10px;
`
export const AvatarCollectionVStyled = styled(AvatarImage).attrs(props => ({
  shadow: true
}))`
  width: 5rem;
  height: 4.8rem;
  margin: 0 0.5rem 0 0;
  border-radius: 5px;
`
export const CardCollectionBlockStyled = styled(CardBlockStyled)`
  height: 3rem;
`
export const CardFooterStyled = styled(FooterOverlayStyled)`
  width: 100%;
  margin: 0;
  position: relative;
  margin-top: -3.5rem;
`
export const CardCategoryWhite = styled.div`
  color: ${whiteColor};
  margin: 0;
  font-size: 0.6rem;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
`
export const CardWrapperV = styled.div`
  display: flex;
`

export const IconStyled = styled(Icon)`
  overflow: unset;
  font-size: 12px;
`
export const IconDataStyled = styled.span`
  margin-right: 0.2rem;
`
export const NameStyled = styled.span`
  font-size: ${({ size }) => size > 50 ? 0.4 : (size > 30 ? 0.5 : 0.6)}rem;
  color: ${({ color }) => color};
  & span {
    font-size: 0.6rem;
  }
`
export const NameEllipsisStyled = styled(EllipsisStyled)`
  font-size: ${({ size }) => size > 50 ? 0.5 : (size > 30 ? 0.6 : 0.7)}rem;
  color: ${({ color }) => color};
  & span {
    font-size: 0.6rem;
  }
`
export const NameVStyled = styled(NameStyled)`
  color: ${blackColor};
  font-size: 0.7rem;
  font-weight: bold;
  margin: 0.25rem 0.25rem 0.3rem;
  display: block;
`

export const InfoWrapperCollectionStyled = styled(InfoWrapperStyled)`
  height: 1rem;
  margin-bottom: 0.8rem;
  flex-direction: row;
  justify-content: center;
`